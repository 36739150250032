<template>
  <div class="me">
    <TogglePanel
      :title="title"
      :icon="togglePanelIcons"
      :collapsed="collapsed"
      persistent="synoptic_control_appearance"
      @changed="$emit('changed', $event)"
    >
      <template v-slot:title><slot name="title"></slot></template>
      <slot name="before"></slot>
      <div class="style-copy" v-if="applicable">
        <div class="style-copy-title">
          {{ $t("style_and_format_copy") }}
        </div>
        <label class="label-inline" for="editor-style-background">
          <span
            class="clicable"
            @click.stop.prevent="enableApplicableStyle(true)"
          >
            <i
              :class="
                applicableStyleEnabled
                  ? 'fa fa-check-circle-o'
                  : 'fa fa-circle-o'
              "
            ></i>
            {{ $t("apply") }}
          </span>
          <span
            class="clicable"
            style="margin-left: 10px"
            @click.stop.prevent="enableApplicableStyle(false)"
          >
            <i
              :class="
                !applicableStyleEnabled
                  ? 'fa fa-check-circle-o'
                  : 'fa fa-circle-o'
              "
            ></i>
            {{ $t("reset") }}
          </span>
        </label>
        <div class="style-copy-body">
          <div
            class="btn btn-xs"
            @click.stop.prevent="setApplicableStyle('style')"
            :class="
              applicableStyle && applicableStyle.style
                ? 'style-copy-active'
                : ''
            "
          >
            <i class="fa fa-paint-brush"></i>
            <span>{{ $t("style") }}</span>
          </div>
          <template v-if="'data_id' in control">
            <div
              class="btn btn-xs"
              @click.stop.prevent="setApplicableStyle('format')"
              :class="
                applicableStyle && applicableStyle.format
                  ? 'style-copy-active'
                  : ''
              "
            >
              <i class="fa fa-dollar"></i>
              <span>{{ $t("data_format") }}</span>
            </div>
            <div
              class="btn btn-xs"
              @click.stop.prevent="setApplicableStyle('both')"
              :class="
                applicableStyle && applicableStyle.both
                  ? 'style-copy-active'
                  : ''
              "
            >
              <i class="fa fa-paint-brush"></i><i class="fa fa-dollar"></i>
              <span>{{ $t("both") }}</span>
            </div>
          </template>
        </div>
      </div>
      <ControlRectProperties
        v-if="control"
        :control="control"
        style="margin-bottom: 10px"
      />
      <!-- PADDING -->
      <slot name="padding">
        <InlineDimensionForm
          v-if="style['padding']"
          title="padding"
          v-model="padding"
          v-bind:lockables="[0, 1, 2, 3]"
          v-bind:labels="['top', 'right', 'bottom', 'left']"
        />
      </slot>
      <!-- ROTATION -->
      <ControlRotation
        v-if="control"
        :control="control"
        class="rotation-container"
      />
      <!-- COLORS -->
      <div
        class="form-inline"
        v-if="style['color'] || style['background-color']"
      >
        <div class="form-group form-group-sm">
          <label class="label-inline" for="editor-style-background">
            {{ $t("synoptic.colors") }}
          </label>
          <ColorPicker
            class="color-picker"
            v-bind:title="$t('synoptic.text_color')"
            v-model="style['color']"
            icon="fa fa-paint-brush"
            style=""
            data-testid="color"
            v-if="style['color']"
          />
          <ColorPicker
            class="color-picker"
            v-bind:title="$t('synoptic.background_color')"
            v-model="style['background-color']"
            icon="background"
            style="display: inline-block; vertical-align: bottom"
            data-testid="background-color"
            v-if="style['background-color']"
          />
        </div>
      </div>
      <!-- BORDER -->
      <div class="form-inline" v-if="style['border']">
        <div class="form-group form-group-sm">
          <label
            class="label-inline"
            for="editor-border-color"
            style="vertical-align: bottom"
            >{{ $t($attrs.borderLabel || "synoptic.border") }}</label
          >
          <ColorPicker
            id="editor-border-color"
            class="color-picker"
            v-model="border.color"
            icon="fa fa-square-o"
            data-testid="border-color"
          />
          <select
            name="borderStyle"
            class="form-control form-group-sm medium"
            v-model="border.style"
            data-testid="border-style"
          >
            <option
              v-for="(borderStyle, index) in borderStyles"
              :key="index"
              :value="borderStyle"
            >
              {{ $t(`synoptic.${borderStyle}`) }}
            </option>
          </select>
          <input
            type="text"
            :class="[
              'form-control short text-center',
              { 'invalid-field': isInvalidValue(border.width) }
            ]"
            data-testid="border-width"
            v-model="border.width"
            data-toggle="popover"
            data-trigger="hover"
            data-placement="top"
            :data-content="
              isInvalidValue(border.width)
                ? invalidValueMessage({ units: 1 })
                : ''
            "
          />
        </div>
      </div>
      <div class="form-inline" v-if="style['border-radius']">
        <div
          class="form-group form-group-sm"
          style="display: flex; align-items: center"
        >
          <label
            class="label-inline"
            for="editor-border-radius"
            style="vertical-align: bottom"
            >{{ $t("synoptic.border_radius") }}</label
          >
          <v-select
            id="editor-border-radius"
            :class="{ 'invalid-field': isInvalidValue(style['border-radius']) }"
            v-model="style['border-radius']"
            label="name"
            :options="borderRadiusList"
            :clearable="false"
            :taggable="true"
            :createOption="(value) => ({ name: value, value })"
            :reduce="(option) => option.value"
            @option:created="
              (option) =>
                !isInvalidValue(option.value)
                  ? borderRadiusList.push(option)
                  : null
            "
            data-toggle="popover"
            data-trigger="hover"
            data-placement="top"
            :data-content="
              isInvalidValue(style['border-radius'])
                ? invalidValueMessage()
                : ''
            "
            data-testid="border-radius"
          >
          </v-select>
        </div>
      </div>
      <slot name="after"></slot>
    </TogglePanel>
    <!-- text/font -->
    <TogglePanel
      v-if="style['font-family']"
      :icon="textTogglePanelIcons"
      :collapsed="collapsed"
      title="text"
      persistent="synoptic_control_text"
    >
      <!-- Font family selection -->
      <div
        class="form-group form-group-sm"
        v-if="style['font-family'] || style['font-size']"
      >
        <label class="label-inline" data-testid="current-font-size"
          >{{ $t("synoptic.font") }}
          <small>({{ style["font-size"] }}) </small></label
        >
        <div class="input-group">
          <select
            class="form-control"
            v-model="style['font-family']"
            v-if="style['font-family']"
            style="font-weight: 600; font-size: 10pt; padding: 0"
            :style="{ 'font-family': style['font-family'] }"
            data-testid="font-family"
          >
            <option
              v-for="(font, index) in fonts"
              :value="font.value"
              :key="index"
              :style="{ 'font-family': font.value }"
            >
              {{ font.name }}
            </option>
          </select>
          <div class="input-group-addon no-padding" v-if="style['font-size']">
            <input
              class="input-font-size"
              list="fontSizeList"
              v-model="fontSize"
              onfocus="this.value=''"
              @blur="onFontSizeBlur"
              @keydown.esc="onFontSizeBlur"
              data-testid="font-size"
            />
            <datalist id="fontSizeList">
              <option
                :value="size"
                v-for="(size, ix) in fontSizeList"
                :key="ix"
              />
            </datalist>
          </div>
        </div>
        <div
          class="btn-group btn-group-justified"
          role="group"
          aria-label="Font"
        >
          <div class="btn-group" role="group">
            <button
              class="btn btn-sm"
              :class="
                style['font-weight'] == 'bold' ? 'btn-primary' : 'btn-default'
              "
              @click="toggleBold"
              data-testid="font-weight"
            >
              <span class="glyphicon glyphicon-bold"></span>
            </button>
          </div>
          <div class="btn-group" role="group">
            <button
              class="btn btn-sm"
              :class="
                style['font-style'] == 'italic' ? 'btn-primary' : 'btn-default'
              "
              @click="toggleItalic"
              data-testid="font-style"
            >
              <span class="glyphicon glyphicon-italic"></span>
            </button>
          </div>
          <template v-if="style['text-decoration']">
            <div class="btn-group" role="group">
              <button
                type="button"
                class="btn btn-sm"
                :class="
                  (style['text-decoration'] || '').includes('underline')
                    ? 'btn-primary'
                    : 'btn-default'
                "
                @click="toggleUnderline"
                data-testid="text-decoration-underline"
              >
                <span class="fa fa-underline"></span>
              </button>
            </div>
            <div class="btn-group" role="group">
              <button
                type="button"
                class="btn btn-sm"
                :class="
                  (style['text-decoration'] || '').includes('line-through')
                    ? 'btn-primary'
                    : 'btn-default'
                "
                @click="toggleLinethrough"
                data-testid="text-decoration-line-through"
              >
                <span class="fa fa-strikethrough"></span>
              </button>
            </div>
          </template>
        </div>
      </div>
      <!-- Horizontal and Vertical text alignment -->
      <div
        class="form-group form-group-sm form-group-text-align"
        v-if="style['text-align']"
      >
        <label for="editor-text-align">
          {{ $t("synoptic.text_align") }}
        </label>
        <div class="btn-group" role="group" aria-label="Text Align">
          <button
            type="button"
            @click="style['text-align'] = 'left'"
            class="btn btn-sm"
            :class="
              style['text-align'] == 'left' ? 'btn-primary' : 'btn-default'
            "
            data-testid="text-align-left"
          >
            <span
              class="glyphicon glyphicon-align-left"
              aria-hidden="true"
            ></span>
          </button>
          <button
            type="button"
            @click="style['text-align'] = 'center'"
            class="btn btn-sm"
            :class="
              style['text-align'] == 'center' ? 'btn-primary' : 'btn-default'
            "
            data-testid="text-align-center"
          >
            <span
              class="glyphicon glyphicon-align-center"
              aria-hidden="true"
            ></span>
          </button>
          <button
            type="button"
            @click="style['text-align'] = 'right'"
            class="btn btn-sm"
            :class="
              style['text-align'] == 'right' ? 'btn-primary' : 'btn-default'
            "
            data-testid="text-align-right"
          >
            <span
              class="glyphicon glyphicon-align-right"
              aria-hidden="true"
            ></span>
          </button>
          <button
            v-if="control && control.synopticComponent.alignJustify != false"
            type="button"
            @click="style['text-align'] = 'justify'"
            class="btn btn-sm"
            :class="
              style['text-align'] == 'justify' ? 'btn-primary' : 'btn-default'
            "
            data-testid="text-align-justify"
          >
            <span
              class="glyphicon glyphicon-align-justify"
              aria-hidden="true"
            ></span>
          </button>
        </div>
        <div
          class="btn-group"
          role="group"
          aria-label="Text Align"
          v-if="style['vertical-align']"
        >
          <button
            type="button"
            @click="style['vertical-align'] = 'top'"
            class="btn btn-sm vertical top"
            :class="
              style['vertical-align'] == 'top' ? 'btn-primary' : 'btn-default'
            "
            data-testid="text-align-top"
          >
            <div class="fa fa-long-arrow-up" aria-hidden="true"></div>
          </button>
          <button
            type="button"
            @click="style['vertical-align'] = 'middle'"
            class="btn btn-sm vertical middle"
            :class="
              style['vertical-align'] == 'middle'
                ? 'btn-primary'
                : 'btn-default'
            "
            data-testid="text-align-middle"
          >
            <div class="fa fa-compress" aria-hidden="true"></div>
            <span>-----</span>
          </button>
          <button
            type="button"
            @click="style['vertical-align'] = 'bottom'"
            class="btn btn-sm vertical bottom"
            :class="
              style['vertical-align'] == 'bottom'
                ? 'btn-primary'
                : 'btn-default'
            "
            data-testid="text-align-bottom"
          >
            <div class="fa fa-long-arrow-up" aria-hidden="true"></div>
          </button>
        </div>
      </div>
      <!-- Vertical content aligment (if no text) -->
      <div
        class="form-group form-group-sm"
        v-else-if="style['vertical-align']"
        style="padding: 0 5px; margin: -20px 0 0px -5px"
      >
        <label class="label-inline">{{ $t("vertical") }}</label>
        <select v-model="style['vertical-align']" class="form-control">
          <option value="top">{{ $t("top") }}</option>
          <option value="middle">{{ $t("middle") }}</option>
          <option value="bottom">{{ $t("bottom") }}</option>
        </select>
      </div>
    </TogglePanel>
  </div>
</template>

<script>
import ColorPicker from "@/components/editor/color-picker";
import isEqual from "lodash/isEqual";
import InlineDimensionForm from "./inline-dimension-form.vue";
import ControlRectProperties from "./control-rect-properties.vue";
import ControlRotation from "./control-rotation";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";

import Controls from "@/assets/dashboard/controls";
import merge from "lodash/merge";

const defCtrl = (componentName) => {
  var ctrl = Controls.find(
    ({ template }) => template.synopticComponent.componentName == componentName
  );
  return ctrl ? JSON.parse(JSON.stringify(ctrl.template)) : null;
};

export default {
  name: "ControlStyleProperties",
  components: {
    ColorPicker,
    InlineDimensionForm,
    TogglePanel,
    ControlRectProperties,
    ControlRotation
  },
  props: {
    control: {
      type: Object,
      required: false,
      default: () => null
    },
    value: {
      type: Object,
      required: false,
      default: () => ({})
    },
    title: {
      type: String,
      required: false,
      default: "appearance"
    },
    applicable: {
      type: Boolean,
      default: false,
      required: false
    },
    togglePanelIcons: {
      type: Object,
      default: () => ({
        collapse: "fa-caret-square-o-up",
        expand: "fa-caret-square-o-down",
        before: "fa fa-magic"
      }),
      required: false
    },
    textTogglePanelIcons: {
      type: Object,
      default: () => ({
        collapse: "fa-caret-square-o-up",
        expand: "fa-caret-square-o-down",
        before: "fa fa-font"
      }),
      required: false
    },
    collapsed: {
      type: Boolean,
      default: true,
      required: false
    },
    persistent: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      style: {},
      border: {
        color: "black",
        style: "solid",
        width: "1"
      },
      padding: [0, 0, 0, 0],
      borderStyles: [
        "solid",
        "dashed",
        "dotted",
        "double",
        "groove",
        "ridge",
        "inset",
        "outset",
        "none"
      ],
      fonts: [
        { name: "Arial", value: "Arial, Helvetica, sans-serif" },
        { name: "Arial Black", value: '"Arial Black", Gadget, sans-serif' },
        {
          name: "Comic Sans MS",
          value: '"Comic Sans MS", cursive, sans-serif'
        },
        { name: "Courier New", value: '"Courier New", Courier, monospace' },
        { name: "Georgia", value: "Georgia, serif" },
        { name: "Impact", value: "Impact, Charcoal, sans-serif" },
        {
          name: "Lucida Console",
          value: '"Lucida Console", Monaco, monospace'
        },
        {
          name: "Lucida Sans Unicode",
          value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif'
        },
        {
          name: "Palatino Linotype",
          value: '"Palatino Linotype", "Book Antiqua", Palatino, serif'
        },
        {
          name: "Source Sans Pro",
          value: '"Source Sans Pro", sans-serif'
        },
        { name: "Tahoma", value: "Tahoma, Geneva, sans-serif" },
        { name: "Times New Roman", value: '"Times New Roman", Times, serif' },
        {
          name: "Trebuchet MS",
          value: '"Trebuchet MS", Helvetica, sans-serif'
        },
        { name: "Verdana", value: "Verdana, Geneva, sans-serif" }
      ],
      colorCodeRegex: /(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))/gi,
      unitRegex: /^\d+([\w|%]*)$/,
      propUpdate: false,
      fontSizeList: [
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        14,
        16,
        18,
        24,
        32,
        60,
        72,
        84,
        96,
        108,
        120
      ],
      borderRadiusList: [
        { name: this.$t("no"), value: "0px" },
        { name: "3px", value: "3px" },
        { name: "5px", value: "5px" },
        { name: "10px", value: "10px" },
        { name: "20%", value: "20%" },
        { name: "50%", value: "50%" }
      ],
      fontSizeUnit: null,
      applicableStyleEnabled: true
    };
  },
  computed: {
    fontSize: {
      set(value) {
        if (value) this.style["font-size"] = value + this.fontSizeUnit;
      },
      get() {
        return this.style["font-size"].replace(this.fontSizeUnit, "");
      }
    },
    controlId() {
      return this?.control?.id || "";
    },
    applicableStyle() {
      return this.applicable
        ? this.$store.getters["dashboard/applicableStyle"] || null
        : null;
    },
    canApplyStyle() {
      if (!this.applicableStyle) return false;
      if (!this.applicableStyleEnabled) return true;
      if (this.applicableStyle.controlId == this.controlId) return false;
      var siblings = [
        "SynopticStaticLabel",
        "SynopticFormattedDisplayValue",
        "SynopticDataValueInput",
        "SynopticIconizedDisplayValue",
        "SynopticStatusLabel"
      ];
      return (
        this.applicableStyle.componentName ==
        this.control.synopticComponent.componentName ||
        (siblings.indexOf(this.applicableStyle.componentName) >= 0 &&
          siblings.indexOf(this.control.synopticComponent.componentName) >= 0)
      );
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && !isEqual(val, this.style)) {
          let defaultStyle =
            (this?.control?.synopticComponent &&
              defCtrl(this.control.synopticComponent.componentName)
                ?.synopticComponent?.style) ??
            {};
          this.propUpdate = true;
          this.style = merge(defaultStyle, val);
          this.$nextTick(() => (this.propUpdate = false));
          if (val.border) {
            this.parseBorderValues(val.border);
          }
          if (val["font-family"]) {
            this.parseFontFamily(val["font-family"]);
          }
          if (val["font-size"]) {
            this.fontSizeUnit = this.unitRegex.exec(val["font-size"])[1];
          }
          if (typeof val["padding"] == "string") {
            this.parsePadding(val["padding"]);
          }
        }
      }
    },
    style: {
      deep: true,
      handler(val) {
        if (!this.propUpdate) {
          this.$emit("input", val);
          if (this.applicableStyle) {
            this.setApplicableStyle();
          }
        }
      }
    },
    border: {
      deep: true,
      handler(val) {
        this.style.border = `${val.width} ${val.style} ${val.color}`;
      }
    },
    padding: {
      deep: true,
      handler(val) {
        this.$set(
          this.style,
          "padding",
          `${val[0]}px ${val[1]}px ${val[2]}px ${val[3]}px`
        );
      }
    },
    controlId(n, o) {
      if (n && o && this.canApplyStyle) {
        this.applyStyle();
        return;
      }
      if (this.applicableStyle) {
        this.setApplicableStyle();
      }
    }
  },
  methods: {
    onFontSizeBlur(ev) {
      if (!ev.target.value) {
        ev.target.value = this.fontSize;
      }
    },
    parsePadding(str) {
      let padding = (str || "").match(/\d+/g).map((i) => parseInt(i));
      this.$set(
        this,
        "padding",
        padding && padding.length == 4 ? padding : [0, 0, 0, 0]
      );
    },
    parseBorderValues(val) {
      let splitted = val.split(" ");
      if (splitted.length > 2) {
        this.border = {
          width: splitted.splice(0, 1)[0],
          style: splitted.splice(0, 1)[0],
          color: splitted.join("")
        }; // update them all at once
      }
    },
    parseFontFamily(val) {
      let font = this.fonts.find(({ value }) => value.includes(val));
      if (!font) font = this.fonts[0];
      this.style["font-family"] = font.value;
    },
    toggleBold() {
      this.$set(
        this.style,
        "font-weight",
        this.style["font-weight"] == "bold" ? "normal" : "bold"
      );
    },
    toggleItalic() {
      this.$set(
        this.style,
        "font-style",
        this.style["font-style"] == "italic" ? "normal" : "italic"
      );
    },
    toggleUnderline() {
      if ((this.style["text-decoration"] || "").includes("underline")) {
        this.style["text-decoration"] = this.style["text-decoration"]
          .replace("underline", "")
          .trim();
        if (!this.style["text-decoration"])
          this.style["text-decoration"] = "none";
      } else {
        this.$set(
          this.style,
          "text-decoration",
          (
            (this.style["text-decoration"] || "").replace("none", "").trim() +
            " underline"
          ).trim()
        );
      }
    },
    toggleLinethrough() {
      if ((this.style["text-decoration"] || "").includes("line-through")) {
        this.style["text-decoration"] = this.style["text-decoration"]
          .replace("line-through", "")
          .trim();
        if (!this.style["text-decoration"])
          this.style["text-decoration"] = "none";
      } else {
        this.$set(
          this.style,
          "text-decoration",
          (
            (this.style["text-decoration"] || "").replace("none", "").trim() +
            " line-through"
          ).trim()
        );
      }
    },
    isInvalidValue(value) {
      return !value?.match?.(/\d(px|%)/);
    },
    invalidValueMessage({ units = 2 } = {}) {
      return this.$tc("invalid_css_value", units);
    },
    setApplicableStyle(what) {
      if (what && ["both", "style", "format"].indexOf(what) >= 0) {
        if ((this?.applicableStyle || {})[what]) {
          this.$store.dispatch("dashboard/setApplicableStyle", null);
          return;
        }
        var entry = {};
        // do not save the default control here.
        // Posponde its search to the apply function, once might apply it to different component type
        entry[what] = this.applicableStyleEnabled ? this.control : true;
        this.$store.dispatch("dashboard/setApplicableStyle", {
          ...{
            controlId: this.control.id,
            componentName: this?.control?.synopticComponent?.componentName,
            enabled: this.applicableStyleEnabled
          },
          ...entry
        });
        if (!this.applicableStyleEnabled && this.canApplyStyle) {
          this.applyStyle();
        }
      } else {
        this.$store.dispatch("dashboard/setApplicableStyle", null);
      }
    },
    enableApplicableStyle(option) {
      this.applicableStyleEnabled = option;
      this.setApplicableStyle();
    },
    applyStyle() {
      if (!this.control || !this.applicableStyle) return;
      var what = this.applicableStyle.both
        ? "both"
        : this.applicableStyle.style
          ? "style"
          : this.applicableStyle.format
            ? "format"
            : "";
      if (!what) return;
      var source = this.applicableStyle.enabled
        ? this.applicableStyle[what]
        : defCtrl(this.control.synopticComponent.componentName);
      if (!source) return;
      var control = null;
      if (what == "both") {
        control = {
          ...this.control,
          format: source.format,
          synopticComponent: {
            ...this.control.synopticComponent,
            style: {
              ...source.synopticComponent.style
            },
            clientRect: {
              ...this.control.synopticComponent.clientRect,
              ...{
                width: source.synopticComponent.clientRect.width,
                height: source.synopticComponent.clientRect.height
              }
            }
          }
        };
      } else if (what == "style") {
        control = {
          ...this.control,
          synopticComponent: {
            ...this.control.synopticComponent,
            style: {
              ...source.synopticComponent.style
            },
            clientRect: {
              ...this.control.synopticComponent.clientRect,
              ...{
                width: source.synopticComponent.clientRect.width,
                height: source.synopticComponent.clientRect.height
              }
            }
          }
        };
      } else if (what == "format") {
        control = {
          ...this.control,
          format: source.format
        };
      }
      if (control) {
        this.$store.dispatch("synoptic/updateControl", {
          id: this.control.id,
          control: control
        });
      }
    }
  },
  mounted() {
    if (this.canApplyStyle) {
      this.applicableStyleEnabled = this.applicableStyle.enabled;
      this.applyStyle();
    } else {
      this.setApplicableStyle();
    }
    $(this.$el)
      .find("[data-toggle=popover]")
      .popover();
  }
};
</script>

<style scoped>
.form-inline {
  margin: 0.5rem 0;
}

.label-inline {
  margin-right: 5px;
}

.color-picker {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
}
.form-group > .form-control {
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.short {
  max-width: 19%;
}
.medium {
  max-width: 37%;
}

.input-font-size {
  border: 0;
  width: 42px;
  min-height: 16px;
  padding: 0;
  text-align: center;
}

.input-font-size:hover {
  appearance: menulist !important;
  -moz-appearance: menulist !important;
  -webkit-appearance: menulist !important;
  -o-appearance: menulist !important;
}

input::-webkit-calendar-picker-indicator {
  opacity: 100;
}

.input-font-size:focus {
  border: 0;
  outline: none;
  appearance: menulist !important;
  -moz-appearance: menulist !important;
  -webkit-appearance: menulist !important;
  -o-appearance: menulist !important;
}

#editor-border-radius {
  flex: 1;
  display: inline-block;
  border-radius: 4px;
}

.invalid-field {
  box-shadow: 0 0 2px 1px rgba(216, 32, 32, 0.68);
  border-color: transparent;
}

.style-copy {
  display: block;
  margin: -5px 0 10px 0;
}

.style-copy:after {
  clear: both;
}

.style-copy > div.style-copy-title {
  margin-bottom: 10px;
}

.style-copy > label {
  font-size: 10pt;
  color: #5e5e5e;
  font-weight: 600;
  padding-left: 5px;
}

.style-copy > div.style-copy-body {
  margin: 0;
  white-space: nowrap;
  margin-left: 0px;
}

.style-copy > div.style-copy-body > .btn > span {
  padding: 0 2px;
}

.style-copy > div.style-copy-body > .btn > i {
  color: #999;
}

.style-copy > div.style-copy-body > .btn:hover > i {
  color: #333;
  cursor: pointer;
  opacity: 0.8;
}

.style-copy > div.style-copy-body > .btn.style-copy-active {
  color: #ff9800;
  background: whitesmoke;
  border-radius: 2px;
}

.style-copy > div.style-copy-body > .btn.style-copy-active > i {
  text-shadow: 0px 1px 1px grey;
  color: #ff9800;
}
.form-group-text-align > label {
  display: block;
}
.form-group-text-align > .btn-group {
  margin-right: 5px;
}
.form-group-text-align > .btn-group > button {
  padding: 5px 8px;
}

.form-group-text-align > .btn-group > button.vertical {
  position: relative;
}

.form-group-text-align > .btn-group > button.vertical > div {
  border-top: 1px solid #666;
  width: 12px;
}

.form-group-text-align > .btn-group > button.btn-primary > div {
  border-color: white;
}

.form-group-text-align > .btn-group > button.vertical.top > div {
  padding-top: 1px;
}

.form-group-text-align > .btn-group > button.vertical.middle > div {
  border-top-width: 0;
  transform: rotate(135deg);
}

.form-group-text-align > .btn-group > button.vertical.middle > span {
  margin-left: -13px;
  letter-spacing: -1px;
}

.form-group-text-align > .btn-group > button.vertical.bottom > div {
  transform: rotate(180deg);
}
</style>

<style>
.rotation-container .input-group {
  max-width: 50% !important;
}
</style>
