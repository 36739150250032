var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"me"},[(_vm.busy)?_c('Spin'):_vm._e(),_c('TogglePanel',{staticClass:"hinted-toggle",attrs:{"title":"data_mapping","hint":`${_vm.$t(
      'hints.screen_data_mapping'
    )}<br/>remote_connector: <i class='fa fa-plug'></i> ${
      _vm.remoteConnector ? _vm.remoteConnector.name : ''
    }`}},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"form-group form-group-sm",staticStyle:{"margin-bottom":"0"}},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$tc("panel", 1)))]),(_vm.selectedPanel)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.panelName),expression:"panelName"}],staticClass:"form-control no-padding",attrs:{"title":`${_vm.selectedPanel.originalTitle} - ${_vm.$t('position')} ${_vm.$tc(
            'layout',
            1
          )}: [${parseInt(_vm.selectedPanel.position.row) + 1},${
            parseInt(_vm.selectedPanel.position.col) + 1
          }]`},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.panelName=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.filteredPanelList),function(item){return _c('option',{key:item.name,attrs:{"title":`${item.originalTitle} - ${_vm.$t('position')} ${_vm.$tc(
              'layout',
              1
            )}: [${parseInt(item.position.row) + 1},${
              parseInt(item.position.col) + 1
            }]`},domProps:{"value":item.name}},[_vm._v(" @"+_vm._s(parseInt(item.position.row) + 1)+","+_vm._s(parseInt(item.position.col) + 1)+" - "+_vm._s(item.customTitle || item.originalTitle)+" ")])}),0):_vm._e()]),(_vm.selectedPanel)?[(_vm.selectedPanel.template == 'SynopticPanel')?[(_vm.customControls.length)?_c('div',[_c('TogglePanel',{staticStyle:{"background-color":"transparent","padding":"0 10px"},attrs:{"title":"customizable_controls"}},[_c('div',_vm._l((_vm.customControls),function(control){return _c('div',{key:control.name},[_c('div',{staticClass:"no-select",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();control.collapsed = !control.collapsed}}},[_c('label',{staticClass:"clicable"},[_vm._v("- "+_vm._s(control.name))]),_c('div',{staticClass:"pull-right"},[_c('span',{staticClass:"btn btn-xs"},[_c('i',{staticClass:"fa",class:control.collapsed
                              ? 'fa-caret-square-o-up'
                              : 'fa-caret-square-o-down'})])])]),(!control.collapsed)?_c('ControlDataSelector',{staticStyle:{"margin-bottom":"0"},attrs:{"value":control.data_id,"addon":_vm.$tc('data', 1),"label":"","connectorFilter":(lst) => _vm.connectorListFilter(lst),"allowedTypes":['bool', 'float', 'int', 'string']},on:{"input":function($event){return _vm.setSynopticControlDataId(control, $event)}}}):_vm._e()],1)}),0)])],1):_c('div',{staticClass:"text-center text-danger py-10"},[_vm._v(" "+_vm._s(_vm.$t("titles.there_are_no_named_data_controls"))+" ")])]:[_c('div',{staticClass:"data-list-form"},[(
                _vm.selectedPanel.template == 'DashboardTablePanel' &&
                _vm.tableCellRef.length
              )?_c('TogglePanel',{attrs:{"title":_vm.$tc('data_list', 2)}},[_c('div',_vm._l((_vm.tableCellRef),function(item){return _c('div',{key:item.from,staticClass:"data-item"},[_c('div',{staticClass:"data-item-header clicable",class:{ expanded: !item.collapsed },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();item.collapsed = !item.collapsed}}},[_c('div',{staticClass:"text",attrs:{"title":_vm.itemTitle(item.to || item.from)}},[_c('i',{staticClass:"fa fa-arrows-v"}),_vm._v(" "+_vm._s(_vm.dataName(item.to || item.from))+" ")]),_c('div',{staticClass:"toggle"},[_c('i',{class:item.collapsed
                            ? 'fa fa-chevron-right'
                            : 'fa fa-chevron-down'})])]),(!item.collapsed)?_c('div',{staticClass:"data-item-body"},[_c('div',{staticClass:"address",attrs:{"title":item.address}},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc("cell", 2))+": ")]),_vm._v(" "+_vm._s(item.address)+" ")]),(!item.collapsed)?[(
                          _vm.dataConnectorId(item.to || item.from) ||
                          _vm.screenConnectorId
                        )?_c('ControlDataSelector',{attrs:{"label":"","addon":_vm.$tc('data', 1),"connectorId":_vm.dataConnectorId(item.to || item.from) ||
                          _vm.screenConnectorId,"value":item.to || item.from,"connectorFilter":(lst) => _vm.connectorListFilter(lst)},on:{"input":function($event){return _vm.setCellRef(item, $event)}}}):_c('ControlDataSelector',{attrs:{"label":"","addon":_vm.$tc('data', 1),"value":item.to || item.from,"connectorFilter":(lst) => _vm.connectorListFilter(lst)},on:{"input":function($event){return _vm.setCellRef(item, $event)}}})]:_vm._e()],2):_vm._e()])}),0)]):(_vm.hasDataListSupport)?_c('DataListForm',{attrs:{"labelItems":_vm.$tc('selected', 2, { gender: 'o' }),"labelSelection":_vm.$tc('data_list', 2),"collapsed":_vm.panelDataListConfig.collapsed,"dataSelectionOnly":_vm.panelDataListConfig.dataSelectionOnly,"showAddAllDataButton":_vm.panelDataListConfig.showAddAllDataButton,"showAddNewDataButton":_vm.panelDataListConfig.showAddNewDataButton,"multiConnector":_vm.panelDataListConfig.multiConnector,"selectable":_vm.panelDataListConfig.selectable,"dataListParser":_vm.dataListParser,"connectorFilter":(lst) => _vm.connectorListFilter(lst)},on:{"all":function($event){_vm.panelDataIdListAll = true}},model:{value:(_vm.panelDataIdList),callback:function ($$v) {_vm.panelDataIdList=$$v},expression:"panelDataIdList"}}):_c('div',{staticClass:"text-center text-danger py-10"},[_vm._v(" "+_vm._s(_vm.$t("data_mapping_not_available"))+" ")])],1)]]:_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }